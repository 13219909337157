body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  font-size: 100%;
}
.center,
.container {
  margin-left: auto;
  margin-right: auto;
}
h1 {
  font-size: 2.5rem;
  margin: 0.8rem;
}
h2 {
  font-size: 2rem;
  margin: 0.8rem;
}
h3 {
  font-size: 1.375rem;
  margin: 0.8rem;
}
h4 {
  font-size: 1.125rem;
  margin: 0.8rem;
}
h5 {
  font-size: 1rem;
  margin: 0.8rem;
}
h6 {
  font-size: 0.875rem;
  margin: 0.8rem;
}
p {
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.8;
}
.font-light {
  font-weight: 300;
}
.font-regular {
  font-weight: 400;
}
.font-heavy {
  font-weight: 700;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}
.justify {
  text-align: justify;
}
.container {
  width: 90%;
}
.row {
  position: relative;
  width: 100%;
}
.row [class^='col'] {
  float: left;
  margin: 0.5rem 2%;
  min-height: 0.125rem;
}
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9 {
  width: 96%;
}
.col-1-sm {
  width: 4.33%;
}
.col-2-sm {
  width: 12.66%;
}
.col-3-sm {
  width: 21%;
}
.col-4-sm {
  width: 29.33%;
}
.col-5-sm {
  width: 37.66%;
}
.col-6-sm {
  width: 46%;
}
.col-7-sm {
  width: 54.33%;
}
.col-8-sm {
  width: 62.66%;
}
.col-9-sm {
  width: 71%;
}
.col-10-sm {
  width: 79.33%;
}
.col-11-sm {
  width: 87.66%;
}
.col-12-sm {
  width: 96%;
}
.row::after {
  content: '';
  display: table;
  clear: both;
}
.hidden-sm {
  display: none;
}
@media only screen and (min-width: 33.75em) {
  .container {
    width: 80%;
  }
}
@media only screen and (min-width: 45em) {
  .col-1 {
    width: 4.33%;
  }
  .col-2 {
    width: 12.66%;
  }
  .col-3 {
    width: 21%;
  }
  .col-4 {
    width: 29.33%;
  }
  .col-5 {
    width: 37.66%;
  }
  .col-6 {
    width: 46%;
  }
  .col-7 {
    width: 54.33%;
  }
  .col-8 {
    width: 62.66%;
  }
  .col-9 {
    width: 71%;
  }
  .col-10 {
    width: 79.33%;
  }
  .col-11 {
    width: 87.66%;
  }
  .col-12 {
    width: 96%;
  }
  .hidden-sm {
    display: block;
  }
}
@media only screen and (min-width: 60em) {
  .container {
    width: 75%;
    max-width: 60rem;
  }
}
